import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import firebase from 'firebase/compat/app';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../services/firebase';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB } from 'date-fns/locale';
interface CreateListingProps {}

const CreateListing: React.FC<CreateListingProps> = () => {
  const navigate = useNavigate();
  const [listingTitle, setListingTitle] = useState('');
  const [briefDescription, setBriefDescription] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [spots, setSpots] = useState('');
  const [price, SetPrice] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [uid, setUid] = useState('');

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setUid(user.uid);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const handleCreateListing = async () => {
    const data = {
      title: listingTitle,
      description: briefDescription,
      category,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      spots,
      price,
      targetAudience,
      createdBy: uid,
      createdOn: new Date().toISOString(),
    };
    try {
      const listRef = await addDoc(collection(db, 'listings'), data);
      const listData = {
        listingId: listRef.id,
        userId: uid,
        role: 'admin',
        status: 'active',
        joinedOn: new Date().toISOString(),
      };
      await addDoc(collection(db, 'listing_members'), listData);
      navigate('/');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Box>
        <AppBar position='static' color='primary'>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              aria-label='back'
              onClick={handleBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              Create Listing
            </Typography>
          </Toolbar>
        </AppBar>

        <Box p={4}>
          <Paper elevation={1} sx={{ borderRadius: 2 }}>
            <Box p={4}>
              <Typography variant='h6' gutterBottom>
                Basic Information
              </Typography>

              <TextField
                label='Listing Title'
                variant='outlined'
                value={listingTitle}
                onChange={(e) => setListingTitle(e.target.value)}
                fullWidth
                margin='normal'
              />

              <TextField
                label='Brief Description'
                variant='outlined'
                value={briefDescription}
                onChange={(e) => setBriefDescription(e.target.value)}
                multiline
                rows={3}
                fullWidth
                margin='normal'
              />

              <FormControl variant='outlined' fullWidth margin='normal'>
                <InputLabel>Category</InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value as string)}
                >
                  <MenuItem value='housing'>Housing</MenuItem>
                  <MenuItem value='movement'>Movement</MenuItem>
                  <MenuItem value='services'>Services</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>

          <Paper elevation={1} sx={{ borderRadius: 2, mt: 4 }}>
            <Box p={4}>
              <Typography variant='h6' gutterBottom>
                Location & Time
              </Typography>

              <TextField
                label='Location'
                variant='outlined'
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                fullWidth
                margin='normal'
              />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label='Start Date'
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format='dd/MM/yyyy'
                    slotProps={{
                      textField: { fullWidth: true, margin: 'normal' },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label='End Date'
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format='dd/MM/yyyy'
                    slotProps={{
                      textField: { fullWidth: true, margin: 'normal' },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <TimePicker
                    label='Start Time'
                    value={startTime}
                    onChange={(newValue) => setStartTime(newValue)}
                    format='hh:mm a'
                    slotProps={{
                      textField: { fullWidth: true, margin: 'normal' },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label='End Time'
                    value={endTime}
                    onChange={(newValue) => setEndTime(newValue)}
                    format='hh:mm a'
                    slotProps={{
                      textField: { fullWidth: true, margin: 'normal' },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>

          <Paper elevation={1} sx={{ borderRadius: 2, mt: 4 }}>
            <Box p={4}>
              <Typography variant='h6' gutterBottom>
                Group Details
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      border: `1px solid #E0E0E0`,
                      borderRadius: 1,
                      p: 2,
                    }}
                  >
                    <Typography variant='body2' color='#9E9E9E'>
                      Spots
                    </Typography>
                    <TextField
                      variant='standard'
                      value={spots}
                      type='number'
                      onChange={(e) => setSpots(e.target.value)}
                      inputProps={{ style: { textAlign: 'center' } }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      border: `1px solid #E0E0E0`,
                      borderRadius: 1,
                      p: 2,
                    }}
                  >
                    <Typography variant='body2' color='#9E9E9E'>
                      Price (per spot)
                    </Typography>
                    <TextField
                      variant='standard'
                      value={price}
                      type='number'
                      onChange={(e) => SetPrice(e.target.value)}
                      inputProps={{ style: { textAlign: 'center' } }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <TextField
                label='Target Audience Requirements'
                variant='outlined'
                value={targetAudience}
                onChange={(e) => setTargetAudience(e.target.value)}
                multiline
                rows={3}
                fullWidth
                margin='normal'
              />
            </Box>
          </Paper>

          <Button
            variant='contained'
            color='primary'
            onClick={handleCreateListing}
            fullWidth
            sx={{ mt: 4, borderRadius: 25, p: 2 }}
          >
            Create Listing
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default CreateListing;
